@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary-color: #000;
  --blue-1: #02587b;
  --blue-2: #5cb4c2;
  --blue-3: #97d9db;
  --blue-mapsy: #cafcff;
  --pure-white: #ffffff;
  --white: #e0e8eb;
  --yellow: #f4ba00;
  --grey: #c8e0e9;
  --font-stack: Poppins, sans-serif;
}

#root {
  height: 100%;
}

html {
  background-color: var(--white);
  height: 100%;
}

body {
  font-family: var(--font-stack) !important;
  font-size: 16px;
  color: var(--primary-color);
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  height: 100%;
}

a {
  color: #000;
}
div {
  user-select: none;
}

html:has(.custom-modal.open) {
  overflow: hidden;
}

.custom-modal {
  animation: shade-in 0.2s ease-in forwards;
  backdrop-filter: blur(5px);
  display: none;
  &.open {
    display: block;
  }
}

@keyframes shade-in {
  from {
    display: none;
    opacity: 0;
    backdrop-filter: blur(0px);
  }
}

.close-button {
  border-radius: 50%;
  transition: all 0.2s;
  user-select: none;
  &:active {
    transform: scale(1.3);
  }
  &:hover {
    transform: scale(1.1);
  }
}

html, div, 
.transparent-scroll {
  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--white);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--blue-2);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--blue-1);
  }
}

.css-1el24ot-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 1rem !important;
}

.custom-mapsy-schedule {
  background-color: var(--white) !important;

  .MuiTable-root,
  .MainLayout-background.MainLayout-inlineFlex,
  .MainLayout-ordinaryHeaderBorder,
  .MainLayout-stickyElement.MainLayout-leftPanel,
  td.MuiTableCell-body,
  td.MuiTableCell-root {
    border-color: var(--blue-1) !important;
    border-width: 2px !important;
  }

  .MuiTable-root,
  .MainLayout-background.MainLayout-inlineFlex,
  .MainLayout-ordinaryHeaderBorder,
  .MainLayout-stickyElement.MainLayout-leftPanel {
    background-color: var(--white) !important;
  }

  .MainLayout-dayScaleEmptyCell {
    @media screen and (max-width: 520px) {
      min-width: 50px !important;
    }
  }

  .Layout-timeScaleContainer {
    @media screen and (max-width: 520px) {
      width: 40px !important;
    }
  }

  .Label-label {
    height: 80px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Label-emptyLabel {
    height: 38px !important;
  }

  .Label-text,
  .Cell-dayView {
    color: var(--blue-1) !important;
    font-size: 1rem !important;

    @media screen and (max-width: 520px) {
      font-size: 0.7rem !important;
    }
  }

  .MainLayout-container {
    @extend .transparent-scroll;
    border: 1.5px var(--blue-1) solid !important;
    border-radius: 8px;
    background-color: var(--white) !important;
    max-height: calc(100vh - 220px);
    overflow-y: auto;

    @media screen and (max-width: 520px) {
      max-height: calc(100vh - 140px);
    }
  }

  .Cell-dayOfMonth {
    font-size: 0.9rem !important;

    @media screen and (max-width: 520px) {
      font-size: 0.8rem !important;
    }
  }

  .TitleCell-content .TitleCell-title{
    @media screen and (max-width: 520px) {
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 2.2rem 0 0;
    }
  }
}

.circle-text {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--blue-1);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.text-hover {
  transition: all 0.2s ease;
  &:hover {
    color: var(--blue-1);
  }
}

.blurred-screen::before {
  content: "";
  backdrop-filter: blur(5px);
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

svg.star {
  transition: all 0.3s ease-in;
  cursor: pointer;
  fill: rgba(255, 199, 0, 0);
  &.star-selected {
    fill: rgba(255, 199, 0, 1);
  }
}

@keyframes selectStar {
  from {
    fill: rgba(255, 199, 0, 0);
  }

  to {
    fill: rgba(255, 199, 0, 1);
  }
}

.therapist-card {
  position: relative;
  view-timeline-name: --reveal;

  animation-name: show;
  animation-fill-mode: both;

  animation-timeline: --reveal;
  animation-range: entry 20% cover 30%;
}

@keyframes show {
  from {
    opacity: 0.7;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
.available-time-table {
  .time-cell-content {
    &.available {
      transition: all 0.2s ease;
      cursor: pointer;
      background-color: rgba(92, 180, 194, 0.1);

      span {
        font-weight: 500;
      }

      &:hover {
        transform: scale(1.05);
        background-color: rgba(92, 180, 194, 0.3);
      }
    }

    &.unavailable {
      transition: all 0.2s ease;

      span {
        text-decoration: line-through;
      }
    }
  }
}

.MuiFormControl-root:has(.invalid-input) .MuiFormHelperText-root::before {
  content: url("./assets/svg/error-triangle.svg");
  position: relative;
  display: inline-block;
  width: 18px;
}

.profile-image {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 10px;
  position: relative;
  background-image: url("./assets/img/user-pic.webp");
  background-size: cover;
  background-position: center;
  transition: all 0.2s ease;
  &.loading{
    animation: animated-blur 0.8s ease infinite alternate;
  }
}

@keyframes animated-blur {
  from{
    filter: blur(0);
  }
  to{
    filter: blur(4px);
  }
}

.fade-in{
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.pulse{
  animation: pulse 0.5s 1s ease-in infinite alternate;
}

@keyframes pulse {
  0% {
    scale: 1;
  }  

  50%{
    scale: 1.05;
  }

  100%{
    scale: 1.02;
  }
}

textarea{
  font-size: 1.25rem;
  @media screen and (max-width: 520px) {
    font-size: 1rem;
  }
}

.auto-hidable-box {
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 20px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  display: flex;
  justify-content: center;

  &.hidden {
    transform: translateY(100px);
    opacity: 0;
    pointer-events: none;
  }
}

.MuiLinearProgress-root {
  background-color: var(--blue-3);

  & .MuiLinearProgress-barColorPrimary {
    background-color: var(--blue-1);
  }
}

.MuiCircularProgress-svg {
  & .MuiCircularProgress-circle {
    color: var(--blue-1);
  }
}